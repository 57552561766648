import { Table, TableContainer, TableHead, TableRow, TableBody } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../atoms/tables/TableStyles";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SwitchContainerGTS } from "../atoms/Controls/SwitchGTS";
import LinkWhatsAppGST from "../atoms/Controls/LinkWhatsAppGTS";
import ButtonGST from "../atoms/Controls/ButtonGTS";
import Checkbox from '@mui/material/Checkbox';
export const ListClientGTS = (props: any) => {
    const screen: string = props?.screen === undefined ? "clientes" : props?.screen;
    const subScreen: string = props?.subScreen === undefined ? "" : props?.subScreen;
    const getButtonAction = (row: any) => {
        let btnAction = <></>;
        switch (screen)
        {
            case "clientes":
                btnAction = <ButtonGST onClick={() => props.handleClick(row?.id)}><EditIcon /></ButtonGST>;
                break;
            case "venta":
                btnAction = row?.id === 0 ? <></> : <ButtonGST onClick={() => props.handleDeleteClick(row?.id)}><DeleteIcon /></ButtonGST>;
                break;
            case "venta-busqueda":
                btnAction = <Checkbox onClick={() => props.handleSelectedClick(row)} />;
                break;
        }
        return btnAction;
    }
    return (
        <TableContainer>
        <Table className="table-gts">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID </StyledTableCell>
              <StyledTableCell>Nombre / Alias / Razón Social</StyledTableCell>
              <StyledTableCell className="movile-hidden">Teléfono 1</StyledTableCell>
              <StyledTableCell className="movile-hidden">Teléfono 2</StyledTableCell>
              <StyledTableCell className="movile-hidden">Correo electrónico</StyledTableCell>
              {screen === "clientes" ? <StyledTableCell>Activo</StyledTableCell> : <></>}
              {subScreen === "resume" ? <></> : <StyledTableCell align="right">Acciones</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.clientes?.map((row:any) => {
              return <StyledTableRow key={Math.random()}>
                <StyledTableCell component="th" scope="row">
                  {row?.id}
                </StyledTableCell>
                <StyledTableCell>{row?.nombres + ' ' + row?.apellidos}</StyledTableCell>
                <StyledTableCell className="movile-hidden"><LinkWhatsAppGST telefono={row?.telefono_uno} /></StyledTableCell>
                <StyledTableCell className="movile-hidden"><LinkWhatsAppGST telefono={row?.telefono_dos} /></StyledTableCell>
                <StyledTableCell className="movile-hidden">{row?.correo_electronico}</StyledTableCell>
                {screen === "clientes" ? <StyledTableCell><SwitchContainerGTS checked={row?.estatus === 1}  /></StyledTableCell> : <></>}
                {subScreen === "resume" ? <></> :  <StyledTableCell align="right">{getButtonAction(row)}</StyledTableCell>}
              </StyledTableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
};

export default ListClientGTS