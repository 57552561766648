import { Box, FormControl, FormHelperText, InputLabel, TextField, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IProducto, ProductoDefault, IProductoError, ProductoError } from "../../interfaces/producto-interface";
import { getServiceData, saveServiceData } from "../../store/global-services";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchLabelGTS } from "../../atoms/Controls/SwitchGTS";
import { UrlSiteBase } from '../../Global';
import { IGlobalPoprop } from "../../interfaces/global-interface";

let { UrlBase } = UrlSiteBase();

const AgregarProducto = (globalProp?: IGlobalPoprop) => {
    const { idProducto } = useParams();
    const [producto, setProducto] = useState<IProducto>(ProductoDefault);
    const [error, setError] = useState<IProductoError>(ProductoError);
    const [isActive, setIsActive] = useState<boolean>(idProducto ? (producto?.estatus === 1 ? true : false) : true);
    const [unidades, setUnidades] = useState<any>([]);
    const [presentaciones, setPresentaciones] = useState<any>([]);
    
    const navigate = useNavigate();
    const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        setProducto(producto => ({ ...producto, [id]: value }));
    };
    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        setProducto(producto => ({...producto, [name]: value }));
    };
    const handleCheckbox = () => {
        setIsActive(!isActive);
        setProducto(producto => ({ ...producto, ['estatus']: !isActive ? 1 : 0 }));
    };
    const getProducto = () => {
        getServiceData({ nombre: 'producto', id: idProducto }).then(data => {
            if (data.success) {
                setProducto(data.producto);
            }
        })
    };
    const guardarProducto = () => {
        saveServiceData( {nombre: 'producto', id: idProducto, datos: producto}).then( data => {
            if (data.success) {
                navigate(UrlBase + 'Productos');
                if (globalProp && globalProp.snackHandleOpen)
                    globalProp.snackHandleOpen({open: true, message: data.message});
            }
            else {
                setError(ProductoError);
                for (const [key, value] of Object.entries(data.message)) {
                    setError(error => ({...error, [key]: value}));
                }
            }
        })
    }
    useEffect(() => {
        getServiceData({ nombre: 'unidades' }).then(data => {
            if (data.success) {
                setUnidades(data.unidades);
            }
        });
        getServiceData({ nombre: 'presentaciones' }).then(data => {
            if (data.success) {
                setPresentaciones(data.presentaciones);
            }
        });
    },[])
    useEffect(() => {
        if(idProducto && presentaciones.length > 0 && unidades.length > 0) {
            getProducto();
        }
    }, [unidades, presentaciones, idProducto]);
    const getSafeValue = (field: any) => {
        return field === undefined || field === null ? '' : field;
    };
    const getSafeValNumber = (field: any) => {
        return field === undefined || field === null ? 0 : field;
    };
    return <>
    <Box className="form-gst" component="form" noValidate autoComplete="off">
        <div className="panel-inputs">
          <TextField id="id" value={producto?.id} label="Id" aria-readonly={true} disabled={true} color="secondary" />
          <TextField id="nombre" onChange={handleInputTextChange} value={getSafeValue(producto?.nombre)} label="* Nombre" color="secondary" error={error.nombre !== ''}  helperText={error.nombre} />
          <TextField id="descripcion" onChange={handleInputTextChange} value={getSafeValue(producto?.descripcion)} label="Descripcion" color="secondary" error={error.descripcion !== ''}  helperText={error.descripcion} />
          <TextField id="codigo" onChange={handleInputTextChange} value={getSafeValue(producto?.codigo)} label="Código de barras" color="secondary" error={error.codigo !== ''}  helperText={error.codigo} />
          <TextField id="cantidadProducto" onChange={handleInputTextChange} value={getSafeValue(producto?.cantidadProducto)} label="Cantidad" color="secondary" error={error.cantidadProducto !== ''}  helperText={error.cantidadProducto} />
          <TextField id="precioVenta" onChange={handleInputTextChange} value={getSafeValue(producto?.precioVenta)} label="Precio de Venta" color="secondary" error={error.precioVenta !== ''}  helperText={error.precioVenta} />
          <TextField id="peso" onChange={handleInputTextChange} value={getSafeValue(producto?.peso)} label="Peso" color="secondary" error={error.peso !== ''}  helperText={error.peso} />
          <FormControl className="select-control" error={error.idUnidad !== ''}>
            <InputLabel id="unidad-label">Unidad</InputLabel>
            <Select labelId="lblUnidad" id="idUnidad" name="idUnidad" value={getSafeValNumber(producto?.idUnidad)} label="Estados" onChange={handleSelectChange}>
              <MenuItem key={"item-0"} value={0}>Sin unidad</MenuItem>
              {unidades?.map((uni: any) => (
                <MenuItem key={"item-" + uni?.id} value={uni?.id}>{uni?.nombre}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{error.idUnidad}</FormHelperText>
          </FormControl>
          <FormControl className="select-control" error={error.idPresentacion !== ''}>
            <InputLabel id="unidad-label">Presentación</InputLabel>
            <Select labelId="lblPresentacion" id="idPresentacion" name="idPresentacion" value={getSafeValNumber(producto?.idPresentacion)} label="Presentación" onChange={handleSelectChange}>
              <MenuItem key={"item-0"} value={0}>Sin presentación</MenuItem>
              {presentaciones?.map((pres: any) => (
                <MenuItem key={"item-" + pres?.id} value={pres?.id}>{pres?.nombre}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{error.idUnidad}</FormHelperText>
          </FormControl>
          <FormControl className="check-control" error={error.estatus !== ''}>
            <SwitchLabelGTS label="Activo" id="estatus" checked={isActive} onChange={handleCheckbox} />
          </FormControl>
        </div>
        <div className="panel-actions">
          <ButtonGST onClick={() => navigate(UrlBase + 'Productos')}>Cancelar</ButtonGST>
          <ButtonGST onClick={guardarProducto}>Guardar</ButtonGST>
        </div>
    </Box>
    </>;
};

export default AgregarProducto;