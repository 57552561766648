import { Box, FormControl, FormHelperText, InputLabel, TextField, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getServiceData, saveServiceData } from "../../store/global-services";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchLabelGTS } from "../../atoms/Controls/SwitchGTS";
import { UrlSiteBase, isEmpty } from '../../Global';
import { IGlobalPoprop } from "../../interfaces/global-interface";
import { IDistribuidora, DistribuidoraDefault, IDistribuidoraError, DistribuidoraError } from "../../interfaces/distribuidora-interface";

let { UrlBase } = UrlSiteBase();

const AgregarDistribuidora = (globalProp?: IGlobalPoprop) => {
    const { idDistribuidora } = useParams();
    const [distribuidora, setDistribuidora] = useState<IDistribuidora>(DistribuidoraDefault);
    const [estados, setEstados] = useState<any>([]);
    const [isActive, setIsActive] = useState<boolean>(idDistribuidora ? (distribuidora?.estatus === 1 ? true : false) : true);
    const [isActiveAlmacen, setIsActiveAlmacen] = useState<boolean>(idDistribuidora ? (distribuidora?.tieneAlmacen === 1 ? true : false) : false);
    const [error, setError] = useState<IDistribuidoraError>(DistribuidoraError);
        
    const navigate = useNavigate();

    const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        setDistribuidora(distribuidora => ({ ...distribuidora, [id]: value }));
    };

    const handleInputNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        if (isNaN(Number(value)))
            return;
        setDistribuidora(distribuidora => ({ ...distribuidora, [id]: value }));
    };
    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        setDistribuidora(distribuidora => ({...distribuidora, [name]: value }));
    };
    const handleCheckbox = () => {
        setIsActive(!isActive);
        setDistribuidora(cliente => ({ ...cliente, ['estatus']: !isActive ? 1 : 0 }));
      }
    const guardarDistribuidora = () => {
        saveServiceData( {nombre: 'distribuidora', id: idDistribuidora, datos: distribuidora}).then( data => {
            if (data.success) {
                navigate(UrlBase + 'Distribuidoras');
                if (globalProp && globalProp.snackHandleOpen)
                    globalProp.snackHandleOpen({open: true, message: data.message});
            }
            else {
                setError(DistribuidoraError);
                for (const [key, value] of Object.entries(data.message)) {
                    setError(error => ({...error, [key]: value}));
                }
            }
        });
    };
    useEffect(() => {
        getServiceData({ nombre: 'estados', parametros: { estatus: 1 }}).then(data => {
          if (data.success && data.estados)
            setEstados([...data.estados]);
        });
      }, []);
    useEffect(() => {
        if (idDistribuidora && estados.length > 0) {
            getServiceData({ nombre: 'distribuidora', id: idDistribuidora }).then(data => {
                if (data.success && data.distribuidora)
                    setDistribuidora(data.distribuidora);
            });
        }
    }, [idDistribuidora, estados])
    const getSafeValue = (field: any) => {
        return field === undefined || field === null ? '' : field;
    };
    return <>
        <Box className="form-gst" component="form" noValidate autoComplete="off">
            <div className="panel-inputs">
                <TextField id="id" value={distribuidora?.id} label="Id" aria-readonly={true} disabled={true} color="secondary" />
                <TextField id="clave" onChange={handleInputTextChange} value={distribuidora?.clave} label="* Clave" color="secondary" error={error.clave !== ''}  helperText={error.clave} />
                <TextField id="nombre" onChange={handleInputTextChange} value={distribuidora?.nombre} label="* Nombre Comercial" color="secondary" error={error.nombre !== ''}  helperText={error.nombre} />
                <TextField id="razonSocial" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.razonSocial)} label="* Razón Social" color="secondary" error={error.razonSocial !== ''}  helperText={error.razonSocial} />
                <TextField id="email" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.email)} label="* Correo Electrónico" error={error.email !== ''}  helperText={error.email} InputLabelProps={{ shrink: isEmpty(distribuidora?.email) }} />
                <TextField id="telefonos" onChange={handleInputNumberChange} value={getSafeValue(distribuidora?.telefonos)} label="* Teléfono" color="secondary" error={error.telefonos !== ''}  helperText={error.telefonos} />
            </div>
            <div className={"panel-inputs "}>
                <TextField id="rfc" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.rfc)} label="RFC" color="secondary" error={error.rfc !== ''}  helperText={error.rfc} />
                <TextField id="direccion" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.direccion)} label="Domicilio" color="secondary" error={error.direccion !== ''}  helperText={error.direccion} />
                <TextField id="numeroExterior" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.numeroExterior)} label="Numero exterior" color="secondary" error={error.numeroExterior !== ''}  helperText={error.numeroExterior} />
                <TextField id="numeroInterior" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.numeroInterior)} label="Numero interior" color="secondary" error={error.numeroInterior !== ''}  helperText={error.numeroInterior} />
                <TextField id="codigoPostal" onChange={handleInputNumberChange} value={getSafeValue(distribuidora?.codigoPostal)} label="Código Postal" color="secondary" error={error.codigoPostal !== ''}  helperText={error.codigoPostal} />
                <TextField id="colonia" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.colonia)} label="Colonia" color="secondary" error={error.colonia !== ''}  helperText={error.colonia} />
                <TextField id="municipio" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.municipio)} label="Municipio" color="secondary" error={error.municipio !== ''}  helperText={error.municipio} />
                <FormControl className="select-control" error={error.idEstado !== ''}>
                    <InputLabel id="estado-label">Estado</InputLabel>
                    <Select labelId="Estado" id="estado" name="idEstado" value={getSafeValue(distribuidora?.idEstado)} label="Estados" onChange={handleSelectChange}>
                    <MenuItem value={0}>Sin estado</MenuItem>
                    {estados?.map((estado: any) => (
                        <MenuItem key={"item-" + estado?.id} value={estado?.id}>{estado?.nombre}</MenuItem>
                    ))}
                    </Select>
                    <FormHelperText>{error.idEstado}</FormHelperText>
                </FormControl>
                <TextField id="latitud" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.latitud)} label="Latitud" color="secondary" error={error.latitud !== ''}  helperText={error.latitud} />
                <TextField id="longitud" onChange={handleInputTextChange} value={getSafeValue(distribuidora?.longitud)} label="Longitud" color="secondary" error={error.longitud !== ''}  helperText={error.longitud} />
                <FormControl className="check-control" error={error.tieneAlmacen !== ''}>
                    <SwitchLabelGTS label="Tiene Almacen" id="tieneAlmacen" checked={isActiveAlmacen} onChange={handleCheckbox} />
                </FormControl>
                <FormControl className="check-control" error={error.estatus !== ''}>
                    <SwitchLabelGTS label="Activo" id="estatus" checked={isActive} onChange={handleCheckbox} />
                </FormControl>
            </div>
            <div className="panel-actions">
                <ButtonGST onClick={() => navigate(UrlBase + 'Distribuidoras')}>Cancelar</ButtonGST>
                <ButtonGST onClick={guardarDistribuidora}>Guardar</ButtonGST>
            </div>
        </Box>
    </>;
};

export default AgregarDistribuidora
        