import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TransitionProps } from '@mui/material/transitions';
import { useState, useEffect } from "react";
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement; },
    ref: React.Ref<unknown>) 
{
    return <Slide direction="up" ref={ref} {...props} />;
});
export const DialogGTS = ({title, open, handleClose, children}: any) => {

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{title}</Typography>
                </Toolbar>
            </AppBar>
            <div className="page-container without-margin">
                {children}
            </div>
        </Dialog>
    )
}
export const DialogGTSList = ({title, open, handleClose, list, selected, handleChange, maxValue}: any) => {
    const [value, setValue] = useState(selected);
    const [openModal, setOpen] = useState(false);
    const [maxValueLst, setMaxValueLst] = useState(-1);
    const radioGroupRef = React.useRef<HTMLElement>(null);
    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        let target = (event.target as HTMLInputElement);
        setValue(target.value);
        handleChange(target.value);
    }
    const handleClickRadio = (value: number) => {
        setValue(value);
        handleChange(value);        
    }
    useEffect(() => {
        setOpen(open);
    }, [open]);
    useEffect(() => {
        setMaxValueLst(maxValue);
    },[maxValue]);
    return (
        <Dialog open={openModal} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{title}</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                <RadioGroup ref={radioGroupRef} aria-label="numeric-list" name="numeric-list" value={value} onChange={handleChangeRadio}>
                {list.map((item: any) => {
                    if (maxValueLst > 0 && item.value > maxValueLst)
                        return <></>;
                    return (<FormControlLabel value={item.value} key={'key-' + item.value} control={<Radio />} label={item.label} onClick={() => handleClickRadio(item.value)} />)
                })}
                </RadioGroup>
            </DialogContent>
        </Dialog>
    )
}
export default DialogGTS;
