import { Table, TableContainer, TableHead, TableRow, TableBody } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles"
import { useNavigate } from "react-router-dom";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchContainerGTS } from "../../atoms/Controls/SwitchGTS";
import { getServiceData } from "../../store/global-services";
import { UrlSiteBase } from '../../Global';
import { IGlobalPoprop } from "../../interfaces/global-interface";
let { UrlBase } = UrlSiteBase();

const ListadoUsuarios = (globalProp?: IGlobalPoprop) => {
  const [usuarios, setUsuarios] = useState<any>();
  const navigate = useNavigate();
  
  useEffect(() => {
    getServiceData({ nombre: 'usuarios' }).then(data => {
      if (data.success)
        setUsuarios([...data.usuarios]);
    });
  }, [])
  const handleClick = (idUsuario: number) => {
    navigate(idUsuario > 0 ? `${UrlBase}Usuario/Editar/${idUsuario}` : `${UrlBase}Usuario/Agregar`);
  }
  return (
    <>
      <div className="panel-actions">
        <ButtonGST onClick={() => handleClick(0)}>Agregar <AddIcon /></ButtonGST>
      </div>
      <TableContainer>
        <Table className="table-gts">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID </StyledTableCell>
              <StyledTableCell>Usuario</StyledTableCell>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell className="movile-hidden">Correo Electrónico</StyledTableCell>
              <StyledTableCell className="movile-hidden">Perfil</StyledTableCell>
              <StyledTableCell className="movile-hidden">Fecha Alta</StyledTableCell>
              <StyledTableCell >Estatus</StyledTableCell>
              <StyledTableCell align="right">Acciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usuarios?.map((row:any) => {
              return <StyledTableRow key={Math.random()}>
                <StyledTableCell component="th" scope="row">
                  {row?.id}
                </StyledTableCell>
                <StyledTableCell>{row?.usuario}</StyledTableCell>
                <StyledTableCell>{row?.nombre + ' ' + row?.apellido }</StyledTableCell>
                <StyledTableCell className="movile-hidden">{row?.email}</StyledTableCell>
                <StyledTableCell className="movile-hidden">{row?.perfil.nombre}</StyledTableCell>
                <StyledTableCell className="movile-hidden">{row?.created_at}</StyledTableCell>
                <StyledTableCell><SwitchContainerGTS checked={row?.estatus === 1}  /></StyledTableCell>
                <StyledTableCell align="right">
                  <ButtonGST onClick={() => handleClick(row?.id)}><EditIcon /></ButtonGST>
                </StyledTableCell>
              </StyledTableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ListadoUsuarios;