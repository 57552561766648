export interface IProducto {
  id: number,
  nombre: string,
  descripcion: string,
  codigo: string,
  presentacion: string,
  peso: number,
  idUnidad: number,
  idPresentacion: number,
  cantidadProducto: number,
  precioVenta: number,
  estatus: number
}
export interface IProductoVenta extends IProducto {
  existencia: number
  cantidad: number
}
export const ProductoDefault = {
  id: 0,
  nombre: '',
  descripcion: '',
  codigo: '',
  presentacion: '',
  peso: 0,
  idUnidad: 0,
  idPresentacion: 0,
  cantidadProducto: 0,
  precioVenta: 0,
  estatus: 1
}
export const ProductoVentaDefault = { ...ProductoDefault, 'existencia': 0, 'cantidad': 0 }
export interface IProductoError {
  nombre: string,
  descripcion: string,
  codigo: string,
  presentacion: string,
  peso: string,
  idUnidad: string,
  idPresentacion: string,
  cantidadProducto: string,
  precioVenta: string,
  estatus: string
}
export const ProductoError = {
  nombre: '',
  descripcion: '',
  codigo: '',
  presentacion: '',
  peso: '',
  idUnidad: '',
  idPresentacion: '',
  cantidadProducto: '',
  precioVenta: '',
  estatus: ''
}