import { Table, TableContainer,  TableHead, TableRow, TableBody } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles";
import { UrlSiteBase, fetchCred } from '../../Global';
import { IGlobalPoprop } from "../../interfaces/global-interface";
let {  UrlBaseApi } = UrlSiteBase();

const ReportePedidos = (globalProp?: IGlobalPoprop) => {
  const [pedidos, setPedidos] = useState<any>();

  const getPedidos = async(nombre: string) => {
    await fetchCred(UrlBaseApi + '/pedido-total')
    .then(response => response.json())
    .then(response => {
      //console.log(response)
      setPedidos([...response.results])
    })
    .catch(error => console.error('Error:', error))
  }

  useEffect(() => {
    getPedidos('');
  }, [])

  return (
    <>
      <TableContainer>
        <Table className="table-gts">
          <TableHead>
            <TableRow>
              <StyledTableCell>Cliente </StyledTableCell>
              <StyledTableCell align="right">Telefono 1</StyledTableCell>
              <StyledTableCell align="right">Telefono 2</StyledTableCell>
              <StyledTableCell align="right">Num. Interior</StyledTableCell>
              <StyledTableCell align="right">Monto Total</StyledTableCell>
              <StyledTableCell align="right">Estuches Totales</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pedidos?.map((row:any) => {
              return <StyledTableRow key={Math.random()}>
                <StyledTableCell component="th" scope="row">
                  {row?.clienteNombre}
                </StyledTableCell>
                <StyledTableCell align="right">{row?.telefonoUno}</StyledTableCell>
                <StyledTableCell align="right">{row?.telefonoDos}</StyledTableCell>
                <StyledTableCell align="right">{row?.numeroInterior}</StyledTableCell>
                <StyledTableCell align="right">{row?.montoTotal}</StyledTableCell>
                <StyledTableCell align="right">{row?.estuchesTotal}</StyledTableCell>
              </StyledTableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ReportePedidos;