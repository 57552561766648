import React from "react";
import { useState } from "react";
import { postServiceData } from "../store/global-services";
import { IServiceData } from "../interfaces/global-interface";
import { UrlSiteBase } from "../Global"
interface AuthContextProviderProps {
  children: JSX.Element
}

const AuthContext = React.createContext({
  user: {isLoggedIn: false, idUsuario: "0"},
  onLogout: () => {},
  onLogin: (email:string, password:string, callback: (opt : any) => void) => {}
});

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  
  let [isLoggedIn, setIsLoggedIn] = useState<boolean>(localStorage.getItem('isLoggedIn') === '1');
  let user = {isLoggedIn: isLoggedIn, 
    idUsuario: localStorage.getItem('idUsuario') + ""
  }
  const { UrlBase } = UrlSiteBase();
    
  const loginHandler = async(email: string, password: string, callback: (opt : any) => void) => {
    const data = {
      "usuario": email,
      "password": password
    };
    let dataService: IServiceData  = { nombre: 'usuario/login', datos: data };
    postServiceData(dataService).then(data => {
      if (data.success){
        localStorage.setItem('isLoggedIn', '1');
        localStorage.setItem('nombreCompleto', data.usuario.nombre + ' ' + data.usuario.apellido );
        localStorage.setItem('distribuidora', data.usuario.distribuidora);
        localStorage.setItem('idDistribuidora', data.usuario.idDistribuidora);
        localStorage.setItem('loginDuration', data.loginDuration);
        setIsLoggedIn(true);
      }
      callback(data);
    });
  };
  
  const logoutHandler = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    window.location.href = UrlBase + "login";
  };
  
  return <AuthContext.Provider
    value={{
      user: {isLoggedIn: user.isLoggedIn, idUsuario: user.idUsuario},
      onLogout: logoutHandler,
      onLogin: loginHandler
    }}
  >
    {props.children}
  </AuthContext.Provider>
}

export default AuthContext;