import { UrlSiteBase } from '../Global';
import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "./auth-context";
let { UrlBase } = UrlSiteBase();

const ProtectedRoute = (): any => {
  const {user} = useContext(AuthContext);
  //console.log("ProtectedRoute:isLoggedIn:" + user.isLoggedIn);
  if (!user.isLoggedIn) {
    return <Navigate to={UrlBase + 'login'} replace />
  }
  return <Outlet />
};

export default ProtectedRoute;