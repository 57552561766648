import { Table, TableContainer, Paper, TableHead, TableRow, TableBody, Backdrop, CircularProgress, Button, Divider, IconButton,  Pagination, Stack } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { getPedidos } from "../../store/pedido-services";
import { IPedido } from "../../interfaces/pedido-interface";
import moment from "moment";
import ClienteAutocomplete from "../../molecules/cliente-autocomplete";
import { UrlSiteBase } from '../../Global';
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { IGlobalPoprop } from "../../interfaces/global-interface";
let { UrlBase } = UrlSiteBase();

const ListadoPedidos = (globalProp?: IGlobalPoprop) => {
  const [pedidos, setPedidos] = useState<IPedido[]>();
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(10);
  const [searching, setSearching] = useState<boolean>(false);
  const [clientes, setClientes] = useState<any>([]);
  const [selectedCliente, setCliente] = useState<any>();
  const navigate = useNavigate();

  /* TODO
  Pass this to the service
  */
  const getClientes = async(nombre:string) => {
    await fetch(UrlBase + '/cliente?search=' + nombre)
    .then(response => response.json())
    .then(response => {
      setClientes([...response.results])
    })
    .catch(error => console.error('Error:', error))
  }

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleClose = () => {
    setOpen(false);
  }

  /* TODO
    Pass this to the comp
  */
    const handleInputChange = (e:any, value:any, reason:any) => {
      getClientes(value);
    }

    /* TODO
    Pass this to the comp
  */
  const handleChange = (e:SyntheticEvent, value: any) => {
    setCliente(value)
  }


  const handleClick = (pedidoId: number) => {
    navigate(UrlBase + (pedidoId > 0 ? `Pedido/Agregar/${pedidoId}` : "Pedido/Agregar/"));
  }

  /*const handleSearchPedido = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPedidoBusqueda(selectedCliente.id)
  }*/

  const searchPedido = () => {
    if (page > 1) {
      setPage(1);
    } else {
      setSearching(true);
    }
  }

  const limpiarBusqueda = () => {
    if (page > 1) {
      setPage(1);
    } else {
      setCliente( '');
      setSearching(true);
    }
  }

  useEffect(() => {
    setOpen(true);
    getPedidos(selectedCliente?selectedCliente.id:'', page)
    .then(response => {
      if(response) {
        setPedidos([...response.results])
        setTotalPages(response.totalPages)
      }
    }).catch(error => console.error('Error:', error))
    .finally(() => {
      setOpen(false);
      setSearching(false);
    });
  }, [page, searching]);

  const formattedPrice = (price: string) => parseFloat(price).toLocaleString('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper
        component="form"
        sx={{ p: '2px 4px', m: '2px 0', display: 'flex', alignItems: 'center', width: 400 }}
      >
        <ClienteAutocomplete clientes={clientes} handleInputChange={handleInputChange} handleChange={handleChange} />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={searchPedido}>
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="limpiar" onClick={limpiarBusqueda}>
          <ClearIcon />
        </IconButton>
      </Paper>
      <div className="panel-actions">
        <ButtonGST onClick={() => handleClick(0)}>Crear</ButtonGST>
      </div>
      <TableContainer>
        <Table  className="table-gts">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{w:50}} width={50}>ID </StyledTableCell>
              <StyledTableCell>Cliente</StyledTableCell>
              <StyledTableCell>Fecha</StyledTableCell>
              <StyledTableCell align="right">Subtotal</StyledTableCell>
              <StyledTableCell align="right">IVA</StyledTableCell>
              <StyledTableCell align="right">Total</StyledTableCell>
              <StyledTableCell align="right">Estado</StyledTableCell>
              <StyledTableCell align="right">Opciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pedidos?.map((row:any) => {
              return <StyledTableRow key={row?.id}>
                <StyledTableCell component="th" scope="row">
                  {row?.id}
                </StyledTableCell>
                <StyledTableCell>{row?.cliente.razonSocial!==''?row?.cliente.razonSocial:`${row?.cliente.nombres} ${row?.cliente.apellidoPaterno}`}</StyledTableCell>
                <StyledTableCell>{moment(row?.fecha).format('YYYY-MM-DD')}</StyledTableCell>
                <StyledTableCell align="right">{formattedPrice(row?.subtotal)}</StyledTableCell>
                <StyledTableCell align="right">{formattedPrice(row?.iva)}</StyledTableCell>
                <StyledTableCell align="right">{formattedPrice(row?.total)}</StyledTableCell>
                <StyledTableCell align="right">{row?.estado}</StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton color="primary" aria-label="editar cliente" onClick={() => handleClick(row?.id)}>
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton color="primary" aria-label="" onClick={() => handleToggleCliente(row?.id, row?.estado)}>
                    {row.activo?<ToggleOnIcon />:<ToggleOffIcon />}
                  </IconButton> */}
                </StyledTableCell>
              </StyledTableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={2}>
        <Pagination count={totalPages} page={page} onChange={handlePaginationChange} />
      </Stack>
    </>
  )
}

export default ListadoPedidos;