import { Switch, FormGroup, FormControlLabel } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';

const SwitchGTS = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#9E1742',
      '&:hover': {
        backgroundColor: alpha('#9E1742', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#9E1742',
    },
}));
export const SwitchLabelGTS = (props: any) => {
    return (
        <FormGroup className={"MuiField-root MuiField-root-switch" + props?.className !== undefined ? " " + props.className : ""}>
            <FormControlLabel control={<SwitchGTS {...props} />} label={props.label} labelPlacement={props?.labelPlacement ? props?.labelPlacement : "end"} />
        </FormGroup>
    )
}
export const SwitchContainerGTS = (props: any) => {
    return (
        <div className="MuiField-root-switch-container">
            <SwitchGTS {...props} />
        </div>
    )
}
export default SwitchGTS