import React, { SyntheticEvent } from "react";
import { Autocomplete, TextField } from "@mui/material";

interface ClienteAutocompleteProps {
  clientes: any[];
  handleInputChange: (e:any, value:any, reason:any) => void;
  handleChange: (e:SyntheticEvent, value: any) => void;
}

const ClienteAutocomplete: React.FC<ClienteAutocompleteProps> = ({
  clientes,
  handleInputChange,
  handleChange,
}) => {
  return (
    <Autocomplete
      clearIcon={false}    
      id="client-autocomplete"
      options={clientes}
      sx={{ width: '90%' }}
      isOptionEqualToValue={(option: any, value: any) => option.nombreCompleto === value.nombreCompleto}
      getOptionLabel={(option) => option.nombreCompleto}
      onInputChange={(e, value, reason) => handleInputChange(e, value, reason)}
      onChange={(e, value) => handleChange(e, value)}
      filterOptions={(clientes) => clientes}
      renderInput={(params) => <TextField {...params} label="Clientes" />}
    />
  );
};

export default ClienteAutocomplete;
