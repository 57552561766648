import React from "react";
import { Card } from "./style";

interface CardProps {
  children: JSX.Element,
  className?: string
}

const CardAtom = (cardProps: CardProps) => {
  return <Card className={`${cardProps.className ?? ''}`}>
    {cardProps.children}
  </Card>
}

export default CardAtom;