export interface ICliente {
  nombres: string,
  apellidos: string,
  telefono: string,
  correo_electronico: string,
  informacion_proporcionada: string,
  comentarios: string,  fecha_alta: Date,
  estatus: number,
  [key: string]: any; // allows any other dynamic key-value pairs
}
export const ClienteDefault = {
  id: 0,
  nombres: '',
  apellidos: '',
  telefono: '',
  correo_electronico: '',
  informacion_proporcionada: '',
  comentarios: '',
  fecha_alta: new Date(),
  estatus: 1
}
export interface IClienteError {
  nombres: string,
  apellidos: string,
  telefono: string,
  correo_electronico: string,
  informacion_proporcionada: string,
  comentarios: string,
  fecha_alta: Date,
  estatus: string
}
export const ClienteError = {
  nombres: '',
  apellidos: '',
  telefono: '',
  correo_electronico: '',
  informacion_proporcionada: '',
  comentarios: '',
  fecha_alta: new Date(),
  estatus: '',
}