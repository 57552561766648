import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
export const TabPanelGTS = ({ children, value, index, className }: any) => {
    return (
        <div className={'TabsGTS-Panel' + (className !== undefined ? " " + className : "")} role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}   
export const TabsGTS = ({ idObject, labels, children, handleTabChange, tabSelected = 0 }: any) => {
  const [value, setValue] = React.useState(tabSelected);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (handleTabChange(newValue))
        setValue(newValue);
  };
  const id = idObject !== undefined ? idObject : "tabsGTS";
  React.useEffect(() => {
      setValue(tabSelected);
  }, [tabSelected])
  return (
    <Box id={id} className="TabsGTS">
        <Box id={id + "-label"} className="TabsGTS-Label">
            <Tabs id={id + "-tabs"} value={value} onChange={handleChange} aria-label="basic tabs">
                { labels.map((label: any, index: number) => 
                    <Tab id={id + "-tab-" + index} key={"label-" + label}  label={label} aria-controls={`tabpanel-${index}`} />
                )}
            </Tabs>
        </Box>
        {children}
    </Box>
  );
}
