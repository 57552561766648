import { TableContainer, Table, TableBody, TableHead, TableRow, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, colors } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles";
import { SwitchContainerGTS } from "../../atoms/Controls/SwitchGTS";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { IGlobalPoprop } from "../../interfaces/global-interface";
import { Fragment, useEffect, useState } from "react";
import { getServiceData, saveServiceData } from "../../store/global-services";

export const Permisos = (globalProp?: IGlobalPoprop) => {
    const [secciones, setSecciones] = useState<any>();
    const [perfiles, setPerfiles] = useState<any>();
    const [idPerfil, setPerfil] = useState<string>('0');
    const [isSecActive, setSecActive] = useState<boolean>(false);
    useEffect(() => {
        getServiceData({ nombre: 'perfiles', parametros: {estatus: 1}}).then(data => {
            setSecActive(data.success);
            if (data.success && data.perfiles)
                setPerfiles(data.perfiles);
        });
    }, [])
    useEffect(() => {
        if (idPerfil !== '0')
            getServiceData({ nombre: 'permisos/seccion/perfil', id: idPerfil }).then(data => {
                if (data.success)
                    setSecciones(data.seccion);
            });
        else
            setSecciones([]);
    }, [idPerfil])
    const saveClick = () => {
        let permisos = [] as any;
        secciones?.map((sec:any) => {
            permisos.push({
                idPermiso: sec.idPermiso === null ? 0 : sec.idPermiso, 
                idSeccion: sec.id, 
                idPerfil: sec.idPerfil, 
                estatus: sec.estatus === 1 ? 1 : 0
            });
        });
        saveServiceData({ nombre: 'permisos', datos: permisos }).then( data => {
            if (globalProp && globalProp.snackHandleOpen)
                globalProp.snackHandleOpen({open: true, message: data.message});
        })
        
    }
    const handleChecked = (id: number) => {
        secciones?.map((sec:any) => {
            if (sec.id === id){
                let isActive = sec.estatus === 1;
                sec.estatus = isActive ? 0 : 1;
            }
        });
        setSecciones([...secciones]);
    }
    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        setPerfil(value);
    }
    if (!isSecActive)
        return <></>;
    return (
        <>
            <div className="panel-actions floating">
                <FormControl className="select-control">
                    <InputLabel id="perfil-label">Perfil</InputLabel>
                    <Select labelId="Perfil" id="perfil" name="idPerfil" value={idPerfil} label="Perfil" onChange={handleSelectChange}>
                        <MenuItem key={"perf-0"} value="0">Selecciona un perfil...</MenuItem>
                        {perfiles?.map((p:any) => <MenuItem key={"perf-" + p.id} value={p.id}>{p.nombre}</MenuItem>)}
                    </Select>
                </FormControl>
                { idPerfil === '0' ? <></> : <ButtonGST onClick={() => saveClick()}>Guardar</ButtonGST> }
            </div>
            { idPerfil === '0' ? <></> : 
                <TableContainer>
                <Table className="table-gts">
                    <TableHead>
                        <TableRow key={"sec-0"}>
                            <StyledTableCell colSpan={3}>Nombre</StyledTableCell>
                            <StyledTableCell className="movile-hidden">Descripción</StyledTableCell>
                            <StyledTableCell>Estatus</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        secciones?.map((row:any) => {
                            if (row?.tipo === 1) {
                                let subSec =  secciones?.filter((sec:any) => sec.idSeccionPadre === row?.id && sec.tipo === 2);
                                return <Fragment key={"frag-" + row?.id}>
                                <StyledTableRow className="bg-color-terciary" key={"sec-" + row?.id}>
                                    <StyledTableCell colSpan={3}>{row?.nombre}</StyledTableCell>
                                    <StyledTableCell className="movile-hidden">{row?.descripcion}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </StyledTableRow>
                                {subSec?.map((rowSub:any) => {
                                    let fun =  secciones?.filter((sec:any) => sec.idSeccionPadre === rowSub?.id && sec.tipo === 3);
                                    return <Fragment key={"subfrag-" + rowSub?.id}>
                                    <StyledTableRow key={"subSec-" + rowSub?.id}>
                                        <StyledTableCell>&nbsp;</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{rowSub?.nombre}</StyledTableCell>
                                        <StyledTableCell className="movile-hidden">{rowSub?.descripcion}</StyledTableCell>
                                        <StyledTableCell><SwitchContainerGTS checked={rowSub?.estatus === 1} onChange={() => handleChecked(rowSub?.id)}  /></StyledTableCell>
                                    </StyledTableRow>
                                    {fun?.map((rowFun:any) => {
                                        return <StyledTableRow key={"fun-" + rowFun?.id}>
                                            <StyledTableCell>&nbsp;</StyledTableCell>
                                            <StyledTableCell>&nbsp;</StyledTableCell>
                                            <StyledTableCell>{rowFun?.nombre}</StyledTableCell>
                                            <StyledTableCell className="movile-hidden">{rowFun?.descripcion}</StyledTableCell>
                                            <StyledTableCell><SwitchContainerGTS checked={rowFun?.estatus === 1} onChange={() => handleChecked(rowFun?.id)}  /></StyledTableCell>
                                        </StyledTableRow>
                                    })}
                                    </Fragment>
                                })}
                                </Fragment>
                            }
                        })}
                    </TableBody>
                </Table>
                </TableContainer>
            }
        </>);
}
export default Permisos;