export interface IPerfil {
    id: number;
    nombre: string;
    descripcion: string;
    estatus: number;
}
export const PerfilDefault = {
    id: 0,
    nombre: '',
    descripcion: '',
    estatus: 1
}
export interface IPerfilError {
    nombre: string;
    descripcion: string;
    estatus: string;
}
export const PerfilError = {
    nombre: '',
    descripcion: '',
    estatus: ''
}