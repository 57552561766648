import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IPerfil, PerfilDefault, IPerfilError, PerfilError } from "../../interfaces/perfil-interface";
import { getServiceData, saveServiceData } from "../../store/global-services";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchLabelGTS } from "../../atoms/Controls/SwitchGTS";
import { UrlSiteBase } from '../../Global';
import { IGlobalPoprop } from "../../interfaces/global-interface";
let { UrlBase } = UrlSiteBase();

export const Perfil = (globalProp?: IGlobalPoprop) => {
    const { idPerfil } = useParams();
    const [perfil, setPerfil] = useState<IPerfil>(PerfilDefault);
    const [error, setError] = useState<IPerfilError>(PerfilError);
    const [open, setOpen] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(idPerfil ? (perfil?.estatus === 1 ? true : false) : true);
    
    const navigate = useNavigate();
    
    const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
        const { id, value } = event.target;
        setPerfil(perfil => ({ ...perfil, [id]: value }));
    };
    const handleCheckbox = () => {
        setIsActive(!isActive);
        setPerfil(perfil => ({ ...perfil, ['estatus']: !isActive ? 1 : 0 }));
    };
    useEffect(() => {   
        if (idPerfil) {
            getServiceData({ nombre: 'perfil', id: idPerfil }).then(data => {
                if (data.success && data.perfil) {
                    setPerfil(data.perfil);
                    setIsActive(data.perfil.estatus === 1 ? true : false);
                }
            }); 
        }   
    }, [idPerfil]);
    const guardarPerfil = () => {
        saveServiceData({ nombre: 'perfil', datos: perfil, id: idPerfil }).then( data => {
            if (data.success) {
                setOpen(true);
                navigate(UrlBase + 'Perfil');
                if (globalProp && globalProp.snackHandleOpen)
                    globalProp.snackHandleOpen({open: true, message: data.message});
            }
            else {
                setError(PerfilError);
                for (const [key, value] of Object.entries(data.message)) {
                    setError(error => ({...error, [key]: value}));
                }
            }
        });
    };
    
    return (
        <>
        <Box className="form-gst" component="form" noValidate autoComplete="off">
            <div className="panel-inputs">   
                <TextField id="id" label="Id" value={perfil.id} disabled />
                <TextField id="nombre" label="* Nombre" onChange={handleInputTextChange} color="secondary" value={perfil.nombre} error={error.nombre !== ''} helperText={error?.nombre} />
                <TextField id="descripcion" label="Descripción" onChange={handleInputTextChange} color="secondary" value={perfil.descripcion} error={error.descripcion !== ''} helperText={error?.descripcion} />
                <SwitchLabelGTS label="Activo" id="estatus" checked={isActive} onChange={handleCheckbox} />
            </div>
            <div className="panel-actions">
            <ButtonGST onClick={() => navigate(UrlBase + 'Perfil')}>Cancelar</ButtonGST>
                <ButtonGST onClick={guardarPerfil}>Guardar</ButtonGST>
            </div>
        </Box>
        </>
    );
};

export default Perfil;