export const UrlSiteBase = () => {
    if (/localhost:[0-9]+/.test(window.location.href))
        return { UrlBase: "/", UrlBaseApi: "http://localhost/gtsonrisa/api", isEnvDev : true, isEnvPreProd: false, isProduction: false };
    else if (window.location.href.indexOf("localhost") > 0)
        return { UrlBase: "/gtsonrisa/", UrlBaseApi: "/gtsonrisa/api", isEnvDev : true, isEnvPreProd: false, isProduction: false };
    else if (window.location.href.indexOf("ti-care.net") > 0)
        return { UrlBase: "/proyectos/gtsonrisa/", UrlBaseApi: "/proyectos/gtsonrisa/api", isEnvDev : false, isEnvPreProd: true, isProduction: false };
    else if (window.location.href.indexOf("galletastusonrisa.com") > 0)
        return { UrlBase: "/", UrlBaseApi: "/api", isEnvDev : false, isEnvPreProd: false, isProduction: true };
    else
        return { UrlBase: "", UrlBaseApi: "", isEnvDev : false, isEnvPreProd: false, isProduction: false };
}
let timeoutId: null | ReturnType<typeof setTimeout> = null;
let stackFecth: string[] = [];
export const fetchCred = (url: string,  options?: any, events?: any) => {
    let bolHasError = false;
    let bolErrorFecth = false;
    let bolUnauthorized = false;
    let strError = "";
    if (timeoutId !== null) clearTimeout(timeoutId);
    document.getElementById("root")?.classList.add("loading");
    stackFecth.push(url);
    const response = fetch(url, {...{ credentials: 'include' }, ...options });
    response.then(resp => {
        var index = stackFecth.indexOf(url);
        if (index !== -1) stackFecth.splice(index, 1);
        if (stackFecth.length === 0)
            timeoutId = setTimeout(function() { document.getElementById("root")?.classList.remove("loading") }, 400);
        let { UrlBase } = UrlSiteBase();
        let bolNoLoginPage = window.location.href !== UrlBase + "login" || window.location.href !== UrlBase + "logout"
        if (resp.status === 401 && bolNoLoginPage){
            events?.onUnauthorized();
        }
        if (resp.status === 403 && events?.onForbidden){
            bolUnauthorized = true;
            response.then(r => r.json()).then(data => {
                events?.onForbidden(data);    
            });
        }
        if (resp.status === 500) {
            bolHasError = true;
            strError = resp.statusText;
        }
        if (resp.status === 500 && events?.onServerError)
            events?.onServerError();
    }).catch(error => { 
        document.getElementById("root")?.classList.remove("loading");
        bolErrorFecth = true;
        strError = error;
        return Promise.resolve({ success: false, message: "Ocurrio un error: " + strError });
    });
    if (bolErrorFecth)
        return Promise.resolve({ success: false, message: "Ocurrio un error: " + strError });
    return response.then(response => { 
        if (bolHasError) {
            if (response.headers.get('content-type')?.includes('json')){
                return Promise.resolve(response.json().then((data: any) => ({ success: false, message: "Ocurrio un error: " + strError + " - " + data.message }))); //return Promise.resolve({ success: false, message: strError });
            }
            return Promise.resolve({ success: false, message: "Ocurrio un error: " + strError});
        }
        if (bolUnauthorized)
            return Promise.resolve({ success: false, message: "No autorizado" });
        events?.onSuccess();
        return response.json() 
    });
};
const toSnakeCase = (str: string) => {
    return str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`).replace(/\s+/g, '_').toLowerCase();
} 

export const convertKeysToSnakeCase = (obj: any): any => {
    const newObj: any = {};
    Object.keys(obj).forEach((key) => {
        const newKey = toSnakeCase(key);
        newObj[newKey] = obj[key];
    });
    return newObj as any;
}
export const isEmpty = (value: string | number | undefined | null): boolean => {
    let returnValue = false;   
    if (typeof value !== 'undefined' && value !== null) {
        if (typeof value === 'string' && value.length > 0) {
            returnValue = true
        } else if (typeof value === 'number' && value > 0) {
            returnValue = true
        }
    }
    return returnValue;
};
export const dateToString =(obj: Date) => {
    let dateString = `${obj.getFullYear()}/${obj.getMonth() + 1}/${obj.getDate()} ${obj.getHours()}:${obj.getMinutes()}:${obj.getMinutes()}`;
    return dateString;
 }