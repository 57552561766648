//import { ICliente } from "../screens/Clientes/interface";
//import zonas from "../screens/Clientes/zonas";

import { IPedido } from "../interfaces/pedido-interface";
import { UrlSiteBase, fetchCred } from '../Global';
let { UrlBase, UrlBaseApi } = UrlSiteBase();

//const UrlBaseApi = process.env.REACT_APP_PATH_API + '';
//const UrlBaseApi = 'http://local.muessca-api.com';

export const savePedido = async(pedidoId: string|undefined, pedido: IPedido) => {
  const params = {
    method: pedidoId ? 'PUT' : 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(pedido)
  };
  const url = pedidoId ? `${UrlBaseApi}/pedido/${pedidoId}`: `${UrlBaseApi}/pedido`;
  try {
    const response = await fetchCred(url, params);
    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
};

/*export const getPedido = async(pedidoId: string): Promise<IPedido | null> => {
  try {
    const response = await fetch(`${UrlBaseApi}/pedido/${pedidoId}`);
    const pedido = convertKeysToSnakeCase(await response.json());
    return pedido;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};*/

export const getPedidos = async(clienteId: string, page: number): Promise<any> => {
  try {
    const response = await fetchCred(`${UrlBaseApi}/pedido?cliente_id=${clienteId}&page=${page}&items=50`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

/*export const getClientes = async(nombre: string, page: number)=> {
  try {
    const response = await fetch(`${UrlBaseApi}/cliente?search=${nombre}&page=${page}&items=50`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

export const toggleCliente = async(clienteId: number, clienteEstado: string) => {
  const params = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const action = clienteEstado === 'Activo' ? 'disable' : 'enable';
  await fetch(`${UrlBaseApi}/cliente/${clienteId}/${action}`, params)
  .then(response => response.json())
  .then(response => {
  })
  .catch(error => console.error('Error:', error))
}

export const getCendis = async() => {
  try {
    const response = await fetch(`${UrlBaseApi}/cendi`);
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}


export const generarNumeroCliente = (): string => {
  const date = new Date(); // create a new Date object with the current date
  const year = date.getFullYear(); // get the four-digit year part of the date

  const randomNum = Math.floor(Math.random() * 90000) + 10000; // generate a random 5-digit number
  const randomStr = randomNum.toString(); // convert the number to a string
  const newClientNumber = `${year}01${randomStr}`;

  return newClientNumber;
}*/

const toSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`).replace(/\s+/g, '_').toLowerCase();
} 

const convertKeysToSnakeCase = (obj: any): any => {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    const newKey = toSnakeCase(key);
    newObj[newKey] = obj[key];
  });
  return newObj as any;
}