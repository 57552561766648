import { UrlSiteBase } from '../../Global';
import { useEffect, useState, useContext } from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import { NavItem } from "react-bootstrap";
import NavGTS from "../../atoms/Nav/NavGTS";
import AuthContext from '../../store/auth-context';
import { IGlobalPoprop } from '../../interfaces/global-interface';
import {getServiceData } from '../../store/global-services';
let { UrlBase } = UrlSiteBase();

const NavbarGTS = ( globalProp?: IGlobalPoprop ) => {
  let idxNav = 0;
  const {user} = useContext(AuthContext);
  let [menu, setMenu] = useState<any>();
  const [expanded, setExpanded] = useState(false);
  //const [expandedUser, setExpandedUser] = useState(false);
  const getMenu = async() => {
    //await fetchCred(UrlBaseApi + '/usuario/menu').then(data => {
    getServiceData({ nombre: 'usuario/menu' }).then(data => {
      clearSelMenu();
      /*let idxActive = getSeleMenu(data.menu);
      console.log(idxActive);*/
      //localStorage['menu'] = JSON.stringify(data.menu);
      setMenu(data.menu);
    });
  }
  useEffect(() => {
    if (user.isLoggedIn || globalProp?.isLoggedIn) 
      getMenu();
    else
      setMenu([]);
  }, [globalProp?.isLoggedIn, user.isLoggedIn]);
  
  const selMenu = (evt : any, parent? : string) => {
    clearSelMenu();
    if (parent){
      setTimeout(() =>{
        let par = document.getElementById(parent);
        par?.classList.add("active");
      }, 400)
    }
    else
      evt.target.classList.add("active");

    setExpanded(false);
  }

  const clearSelMenu = () => {
    let itms = document.querySelectorAll(".nav-tabs .nav-link, .nav-tabs .nav-item.dropdown");
    itms.forEach((ele)=>{
      ele.classList.remove("active");
    });
  };
  const getSeleMenu = (_menu : any)=>{
    let idxActive = -1;
    _menu?.map((element: any, index: number) => {
      const [title, elements] = element;
      let elementss = Object.entries(elements);
      elementss?.map((anotherElement: any, idxSub)=>{
        const [titlee, ruta] = anotherElement;
        if (window.location.href.indexOf(window.location.host + ruta) > 0)
          idxActive = index;
      });
      return;
    });
    return idxActive;
  };
  const CardUser = <>
    <AccountCircleIcon />
    <span className='name-dealer'>
      <span className='name'>{localStorage.getItem("nombreCompleto")}</span>
      <span className='dealer'>{ localStorage.getItem("idDistribuidora") === '0'? 'Sin Distribuidora' : localStorage.getItem("distribuidora")}</span>
    </span>
  </>;
  const Logout = <Nav.Item key="logout">
    <NavGTS.Link id='logoutLink' key="logoutLink" as={Link} to={UrlBase + 'logout'} eventKey={100} >
      <LogoutIcon /> Salir
    </NavGTS.Link>
  </Nav.Item>;
  const Homepage = <Nav.Item key="menuHome">
    <NavGTS.Link key="menuLinkHome" id='menuLinkHome' as={Link} to={UrlBase + 'homepage'} eventKey={1} 
      className='font-color-principal' onClick={selMenu}>Home</NavGTS.Link>
    </Nav.Item> 
  return (
    <header>
    <Navbar expanded={expanded} collapseOnSelect fixed='top' expand="sm" variant='dark'>
      {user.isLoggedIn ? <Navbar.Toggle id="navBarToggle" aria-controls='responsive-navbar-nav' onClick={() => setExpanded(!expanded)} /> : <></>}
      { user.isLoggedIn ?  
      <NavGTS.Dropdown className='nav-user-dropdown' id={'umenu-1'} keyValue={'umenu-1'} title={CardUser}>
        <NavItem className='name-movile'>{localStorage.getItem("nombreCompleto")}</NavItem>
        { Logout }
      </NavGTS.Dropdown>
      : <></>}
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav variant="tabs" activeKey={window.location.pathname} className='bg-color-principal'>
          <>
          { user.isLoggedIn ? Homepage : <></>}
          {
            user.isLoggedIn ? 
            menu?.map((row:any) => {
              if (row?.tipo === 1) {
                let subMenu =  menu?.filter((m:any) => m.idSeccionPadre === row?.id && m.tipo === 2);
                return <NavItem key={"navItm-" + row?.id } id={"navItm-" + row?.id } >
                  <NavGTS.Dropdown id={'menu-' + row?.id} keyValue={row?.id} title={row?.nombre}>
                    <>
                    {subMenu?.map((rowSub:any) => {   
                        return <NavGTS.DropdownItem id={"smenu-" + row?.id + "-" + rowSub?.id} key={rowSub?.nombre + "-" + row?.id + "-" + rowSub?.id} 
                          as={Link} to={UrlBase + rowSub?.url} onClick={(evt)=>{ selMenu(evt, 'menu-' + row?.id) }} >
                          {rowSub?.nombre}
                        </NavGTS.DropdownItem>
                    })}
                    </>
                  </NavGTS.Dropdown>
                </NavItem>
              }
            }) : <></>
          }
          </>      
        </Nav>
      </Navbar.Collapse>
      
    </Navbar>
    </header>
  )
}

export default NavbarGTS;