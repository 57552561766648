import { Button, ButtonProps } from "react-bootstrap";

const ButtonGST = (props: ButtonProps)=>{
    return (
        <>
        <Button {...props} className="btn-gts">{ props.children }</Button>
        </>
    );
}

export default ButtonGST