import React, { useContext } from "react";
import AuthContext from "../../store/auth-context";
import Login from '../../screens/Login/Login';
import { IGlobalPoprop } from "../../interfaces/global-interface";

const Homepage  = (globalProp?: IGlobalPoprop) => {
  const {user} = useContext(AuthContext);
  const snackHandleOpen = (opt: any) => {
    if (globalProp && globalProp.snackHandleOpen)
      globalProp.snackHandleOpen(opt);
  }
  const loginHandle = (data: any) => {
    if (globalProp && globalProp.loginHandler)
      globalProp.loginHandler(data);
  }
  if (user.isLoggedIn)
    return (
      <div>
        Bienvenido "Galletas Nuestro Sabor es Tu Sonrisa"
      </div>
    );
  else
    return (<Login snackHandleOpen={snackHandleOpen} loginHandler={loginHandle} />);
}

export default Homepage;