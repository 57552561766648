import { useEffect } from 'react';
import { UrlSiteBase } from "../../Global"
function Logout() {
  const { UrlBase } = UrlSiteBase();
  useEffect(()=>{
    //authContext.onLogout();
    localStorage.clear();
    window.location.href = UrlBase + "login";
  });
  return (
    <>
    </>
  )
}

export default Logout;