export interface ISeguimientoProspecto {
  idClienteProspecto: number,
  idTipoSeguimiento: number,
  atendio: number,
  comentario: string,
  estatus: number,
  [key: string]: any; // allows any other dynamic key-value pairs
}
export const SeguimientoProspectoDefault = {
  id: 0,
  idClienteProspecto: 0,
  idTipoSeguimiento: 0,
  atendio: 0,
  comentario: '',
  estatus: 1,
  fecha_registro: new Date(),
}
export interface ISeguimientoProspectoError {
  idClienteProspecto: string,
  idTipoSeguimiento: string,
  atendio: string,
  comentario: string,
  estatus: string,
}
export const SeguimientoProspectoError = {
  idClienteProspecto: '',
  idTipoSeguimiento: '',
  atendio: '',
  comentario: '',
  estatus: ''
}