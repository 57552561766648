import { Table, TableContainer, TableHead, TableRow, TableBody, IconButton, TextField, Pagination, Stack, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles";
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { SwitchContainerGTS } from "../../atoms/Controls/SwitchGTS";

import { getServiceData } from "../../store/global-services";
import { ICliente } from "../../interfaces/cliente-interface";
import { UrlSiteBase } from '../../Global';
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { IGlobalPoprop, IServiceData } from "../../interfaces/global-interface";
let { UrlBase } = UrlSiteBase();

const ListadoClientes = (globalProp?: IGlobalPoprop) => {
  const [clientes, setClientes] = useState<ICliente[]>();
  const [textSearch, setClienteBusqueda] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  
  const navigate = useNavigate();
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') { 
      getClientesSearch(); 
      event.preventDefault(); 
    }
  };
  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setClienteBusqueda(value);
  }
  const getClientesSearch = () => {
    getClientes(1);
  }
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const handleClick = (clientId: number, tipo: string) => {
    if (tipo === 'segimiento')
      navigate(`${UrlBase}Cliente-Prospecto/Seguimiento/${clientId}`);
    else
      navigate(clientId > 0 ? `${UrlBase}Cliente-Prospecto/Editar/${clientId}` : `${UrlBase}Cliente-Prospecto/Agregar`);
  };
  const getClientes = (page: number) => {
    let dataService: IServiceData  = { nombre: 'clientes-prospecto' };
    let paramService = {};
    if (page > 1) 
      paramService = { ...paramService, 'page': page };
    if (textSearch.trim().length > 0)
      paramService = { ...paramService, 'search': textSearch.trim() };
    dataService = { ...dataService, 'parametros': paramService };
    getServiceData(dataService).then(data => {
      if (data.success){
        setClientes(data.clientes_prospecto);
        setTotalPages(data.pagination.last_page);
      }
    });
  };
  useEffect(() => {
    getClientes(page);
  }, [page]);

  return (
    <>
      <div className="panel-search">
        <TextField id="search" onChange={handleSearchTextChange} value={textSearch} label="Buscar" color="secondary"
          InputProps={{ endAdornment: 
            <InputAdornment position="end">
              <IconButton onClick={getClientesSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment> }} onKeyDown={handleKeyDown}/>
      </div>
      <div className="panel-actions">
        <ButtonGST onClick={() => handleClick(0, 'insert')}>Agregar</ButtonGST>
      </div>
      <TableContainer>
        <Table className="table-gts">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID </StyledTableCell>
              <StyledTableCell>Nombre Completo</StyledTableCell>
              <StyledTableCell className="movile-hidden">Teléfono</StyledTableCell>
              <StyledTableCell className="movile-hidden">Correo electrónico</StyledTableCell>
              <StyledTableCell>Activo</StyledTableCell>
              <StyledTableCell align="right">Acciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientes?.map((row:any) => {
              return <StyledTableRow key={Math.random()}>
                <StyledTableCell component="th" scope="row">
                  {row?.id}
                </StyledTableCell>
                <StyledTableCell>{row?.nombres + ' ' + row?.apellidos}</StyledTableCell>
                <StyledTableCell className="movile-hidden"><a target="_blank" href={`https://wa.me/${row?.telefono}`}>{row?.telefono}</a></StyledTableCell>
                <StyledTableCell className="movile-hidden">{row?.correo_electronico}</StyledTableCell>
                <StyledTableCell><SwitchContainerGTS checked={row?.estatus === 1}  /></StyledTableCell>
                <StyledTableCell align="right">
                  <ButtonGST onClick={() => handleClick(row?.id, 'editar')}><EditIcon /></ButtonGST>
                </StyledTableCell>
              </StyledTableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 ?
        <Stack spacing={2}>
          <Pagination count={totalPages} page={page} onChange={handlePaginationChange} />
        </Stack>
        : <></>
      }
    </>
  )
}

export default ListadoClientes;