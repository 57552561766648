export interface IDistribuidora {
    id: number,
    clave: string,
    nombre: string,
    razonSocial: string,
    rfc: string,
    latitud: number,
    longitud: number,
    telefonos: string,
    email: string,
    direccion: string,
    numeroExterior: string,
    numeroInterior: string,
    colonia: string,
    municipio: string,
    idEstado: number,
    codigoPostal: string,
    tieneAlmacen: number,
    estatus: number
}
export const DistribuidoraDefault = {
    id: 0,
    clave: '',
    nombre: '',
    razonSocial: '',
    rfc: '',
    latitud: 0,
    longitud: 0,
    telefonos: '',
    email: '',
    direccion: '',
    numeroExterior: '',
    numeroInterior: '',
    colonia: '',
    municipio: '',
    idEstado: 0,
    codigoPostal: '',
    tieneAlmacen: 0,
    estatus: 1
}
export interface IDistribuidoraError {
    clave: string,
    nombre: string,
    razonSocial: string,
    rfc: string,
    latitud: string,
    longitud: string,
    telefonos: string,
    email: string,
    direccion: string,
    numeroExterior: string,
    numeroInterior: string,
    colonia: string,
    municipio: string,
    idEstado: string,
    codigoPostal: string,
    tieneAlmacen: string,
    estatus: string
}
export const DistribuidoraError = {
    clave: '',
    nombre: '',
    razonSocial: '',
    rfc: '',
    latitud: '',
    longitud: '',
    telefonos: '',
    email: '',
    direccion: '',
    numeroExterior: '',
    numeroInterior: '',
    colonia: '',
    municipio: '',
    idEstado: '',
    codigoPostal: '',
    tieneAlmacen: '',
    estatus: ''
}