import { Table, TableContainer, TableHead, TableRow, TableBody } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles"
import { useNavigate } from "react-router-dom";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchContainerGTS } from "../../atoms/Controls/SwitchGTS";
import { UrlSiteBase } from '../../Global';
import { getServiceData } from "../../store/global-services";
import { IGlobalPoprop } from "../../interfaces/global-interface";
let { UrlBase } = UrlSiteBase();


const ListadoPerfiles = (globalProp?: IGlobalPoprop) => {
    const [perfiles, setPerfiles] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        getServiceData({ nombre: 'perfiles' }).then(data => {
          if (data.success)
            setPerfiles([...data.perfiles]);
        });
    }, []);
    const handleClick = (perfilId: number) => {
      navigate(perfilId > 0 ? `${UrlBase}Perfil/Editar/${perfilId}` : `${UrlBase}Perfil/Agregar`);
    }
    return (
      <>
        <div className="panel-actions">
          <ButtonGST onClick={() => handleClick(0)}>Agregar <AddIcon /></ButtonGST>
        </div>
        <TableContainer>
          <Table className="table-gts">
            <TableHead>
              <TableRow>
                <StyledTableCell>ID </StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell className="movile-hidden">Descripción</StyledTableCell>
                <StyledTableCell>Estatus</StyledTableCell>
                <StyledTableCell align="right">Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {perfiles?.map((row:any) => {
                return <StyledTableRow key={Math.random()}>
                  <StyledTableCell component="th" scope="row">
                    {row?.id}
                  </StyledTableCell>
                  <StyledTableCell>{row?.nombre}</StyledTableCell>
                  <StyledTableCell className="movile-hidden">{row?.descripcion}</StyledTableCell>
                  <StyledTableCell><SwitchContainerGTS checked={row?.estatus === 1}  /></StyledTableCell>
                  <StyledTableCell align="right">
                    <ButtonGST onClick={() => handleClick(row?.id)}><EditIcon /></ButtonGST>
                    </StyledTableCell>
                </StyledTableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
};

export default ListadoPerfiles