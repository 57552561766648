export interface IUsuario {
  id: number,
  idPerfil: string,
  idDistribuidora?: string,
  email: string,
  usuario: string,
  password: string,
  password_confirm: string,
  nombre: string,
  apellido: string,
  fechaAlta: Date,
  estatus: number,
  [key: string]: any; // allows any other dynamic key-value pairs
}
export const UsuarioDefault = {
  id: 0,
  idPerfil: '',
  idDistribuidora: '',
  email: '',
  usuario: '',
  password: '',
  password_confirm: '',
  nombre: '',
  apellido: '',
  fechaAlta: new Date(),
  estatus: 1
}
export interface IUsuarioError {
  idPerfil: string,
  idDistribuidora: string,
  email: string,
  usuario: string,
  password: string,
  password_confirm: string,
  nombre: string,
  apellido: string,
  estatus: string
}
export const UsuarioError = {
  idPerfil: '',
  idDistribuidora: '',
  email: '',
  usuario: '',
  password: '',
  password_confirm: '',
  nombre: '',
  apellido: '',
  estatus: ''
}