import { Box, FormControl, FormHelperText, FormLabel, InputLabel, InputAdornment, IconButton, TextField, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Table, TableContainer, TableHead, TableRow, TableBody, Pagination, Stack } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../atoms/tables/TableStyles";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ICliente, ClienteDefault, IClienteError, ClienteError } from "../../interfaces/cliente-prospecto-interface";
import { ISeguimientoProspecto, SeguimientoProspectoDefault, ISeguimientoProspectoError, SeguimientoProspectoError } from "../../interfaces/seguimiento-prospecto-interface";
import { getServiceData, saveServiceData } from "../../store/global-services";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchLabelGTS, SwitchContainerGTS } from "../../atoms/Controls/SwitchGTS";
import { UrlSiteBase, isEmpty, dateToString } from '../../Global';
import { IGlobalPoprop } from "../../interfaces/global-interface";

let { UrlBase } = UrlSiteBase();

const AgregarClienteProspecto = (globalProp?: IGlobalPoprop) => {
  const { idCliente } = useParams();
  const [cliente, setCliente] = useState<ICliente>(ClienteDefault);
  const [seguimiento, setSeguimiento] = useState<ISeguimientoProspecto>(SeguimientoProspectoDefault);
  const [seguimientos, setSeguimientos] = useState<ISeguimientoProspecto[]>();
  const [tieneSeguimiento, setTieneSeguimieto] = useState<boolean>(false);
  const [tiposSeguimiento, setTiposSeguimiento] = useState<any>([]);
  const [error, setError] = useState<IClienteError>(ClienteError);
  const [errorSeguimiento, setErrorSeguimiento] = useState<ISeguimientoProspectoError>(SeguimientoProspectoError);
  const [isActive, setIsActive] = useState<boolean>(idCliente ? (cliente?.estatus === 1 ? true : false) : true);
  const [atendio, setAtendio] = useState<boolean>(false);
  const [infoProspecto, setInfoProspecto] = useState<any>([]);
  const [infoChecked, setInfoChecked] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setCliente(cliente => ({ ...cliente, [id]: value }));
  }
  const handleInputTextChangeSeguimiento = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setSeguimiento(seguimiento => ({...seguimiento, [id]: value }));
  }
  const handleInputNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    if (isNaN(Number(value)))
      return;
    setCliente(cliente => ({ ...cliente, [id]: value }));
  }
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setCliente(cliente => ({...cliente, [name]: value }));
  }
  const handleSelectChangeSeguimiento = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setSeguimiento(seguimiento => ({...seguimiento, [name]: value }));
  }
  const handleCheckbox = () => {
      setIsActive(!isActive);
      setCliente(cliente => ({ ...cliente, ['estatus']: !isActive ? 1 : 0 }));
  };
  const handleCheckAtendio = () => {
    setAtendio(!atendio);
    setSeguimiento(seguimiento => ({ ...seguimiento, ['atendio']: !atendio ? 1 : 0 }));
  };

  const handleCheckboxList = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    let idSel =  id.replace("informacion_proporcionada_", "");
    let infoCheckedTemp = {...infoChecked};
    infoCheckedTemp[idSel] = checked;
    setInfoChecked(infoCheckedTemp);
  };
  const handleClickDeleteSeguimiento = (id: number) => {
    let seg = { id: id, data: { estatus: 0 } }
    saveServiceData( {nombre: 'seguimiento-prospecto', id: seg.id.toString(), datos: seg.data }).then( data => {
      if (data.success)
        updateSeguimiento();
    });
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const guardarCliente = () => {
    let infoCheckedTemp = Object.keys(infoChecked).filter( (key) => infoChecked[key] === true);
    const clienteTemp = { ...cliente, informacion_proporcionada: infoCheckedTemp.join(',') };
    saveServiceData( {nombre: 'cliente-prospecto', id: idCliente, datos: clienteTemp}).then( data => {
      if (data.success) {
        navigate(UrlBase + 'Clientes-Prospecto');
        if (globalProp && globalProp.snackHandleOpen)
          globalProp.snackHandleOpen({open: true, message: data.message});
      }
      else {
        setError(ClienteError);
        for (const [key, value] of Object.entries(data.message)) {
          setError(error => ({...error, [key]: value}));
        }
      }
    });
  };
  const agregarSeguimiento = () => {
    setOpenModal(true);
  }
  const updateSeguimiento = () => {
    getServiceData({id: idCliente, nombre : 'cliente-prospecto' }).then(data => {
      if (data.success && data.seguimientos)
        setSeguimientos(data.seguimientos);
    });
  }
  const guardarSeguimiento = () => {
    seguimiento.idClienteProspecto = Number(idCliente);
    saveServiceData( {nombre: 'seguimiento-prospecto', id: seguimiento.id, datos: seguimiento}).then( data => {
      if (data.success) {
        setSeguimiento(SeguimientoProspectoDefault);
        setOpenModal(false);
        if (globalProp && globalProp.snackHandleOpen)
          globalProp.snackHandleOpen({open: true, message: data.message});
        updateSeguimiento();
      }
      else {
        setErrorSeguimiento(SeguimientoProspectoError);
        for (const [key, value] of Object.entries(data.message)) {
          setErrorSeguimiento(error => ({...error, [key]: value}));
        }
      }
    });
  }
  useEffect(() => {
    getServiceData({ nombre: 'informacion-prospecto', parametros: { estatus: 1 }}).then(data => {
      if (data.success && data.informacionPospecto)
        setInfoProspecto([...data.informacionPospecto]);
    })
  }, []);
  useEffect(() => {
    if (idCliente && infoProspecto.length > 0) {
      getServiceData({id: idCliente, nombre : 'cliente-prospecto' }).then(data => {
        if (data.success && data.cliente){
          setCliente(cliente => ({ ...cliente, ...data.cliente }));
          setIsActive(data.cliente.estatus === 1 ? true : false);
          let infoCheckedTemp: any = {};
          let arrInfoChecked: any = data.cliente.informacion_proporcionada.split(',');
          for (let iInfo = 0; iInfo < arrInfoChecked.length; iInfo++) {
            infoCheckedTemp[arrInfoChecked[iInfo]] = true;
          }
          setInfoChecked(infoCheckedTemp);
          setTieneSeguimieto(data.tiene_acceso_seguimiento);
          setSeguimientos(data.seguimientos);
          setTiposSeguimiento(data.tipos_seguimiento);
        }
      });
    }
  }, [infoProspecto]);
 
  const getSafeValue = (field: any) => {
    return field === undefined || field === null ? '' : field;
  };
  return (
    <>
      <Box className="form-gst" component="form" noValidate autoComplete="off">
        <div className="panel-inputs">
          <TextField id="id" value={cliente.id} label="Id" aria-readonly={true} disabled={true} color="secondary" />
          <TextField id="nombres" onChange={handleInputTextChange} value={cliente.nombres} label="* Nombre(s)" color="secondary" error={error.nombres !== ''}  helperText={error.nombres} />
          <TextField id="apellidos" onChange={handleInputTextChange} value={cliente.apellidos} label="Apellido(s)" color="secondary" error={error.apellidos !== ''}  helperText={error.apellidos} />
          <TextField id="correo_electronico" onChange={handleInputTextChange} value={cliente.correo_electronico} label="* Correo Electrónico" error={error.correo_electronico !== ''}  helperText={error.correo_electronico} InputLabelProps={{ shrink: isEmpty(cliente.correo_electronico) }} />
          <TextField id="telefono" onChange={handleInputNumberChange} value={cliente.telefono} label="* Teléfono" type="phone"  inputProps={{ maxLength: 10 }} color="secondary" error={error.telefono !== ''}  helperText={error.telefono} />
          <FormControl className="check-control" error={error.estatus !== ''}>
            <SwitchLabelGTS label="Activo" id="estatus" checked={isActive} onChange={handleCheckbox} />
          </FormControl>
        </div>  
        <div className="panel-inputs list-informacion-proporcionada">
          <FormLabel id="label-header-informacion_proporcionada">Información Proporcionada</FormLabel>
          <FormControl className="list-check-control" error={error.informacion_proporcionada !== ''}>
              {infoProspecto?.map((info: any) => (
                 <SwitchLabelGTS key={"switch-" + info?.id} className="check-control" label={
                    <><span className="label">{info?.nombre}</span>
                    {info?.descripcion ? <span className="sub-label"> ({info?.descripcion})</span> : <></>}
                  </>
                  } id={"informacion_proporcionada_" + info?.id}  checked={infoChecked[info?.id] ? true : false} onChange={handleCheckboxList} />
              ))}
            <FormHelperText>{error.informacion_proporcionada}</FormHelperText>
          </FormControl>
        </div>
        <div className="panel-inputs">
          <TextField id="comentarios" className="long-textarea" onChange={handleInputTextChange} multiline rows={2} maxRows={4} value={getSafeValue(cliente?.comentarios)} label="Comentarios" color="secondary" error={error.comentarios !== ''}  helperText={error.comentarios} />
        </div>
        {tieneSeguimiento ? 
          <>  
            <div className="panel-inputs list-seguimiento-cliente">
              <FormLabel id="label-header-seguimiento-cliente">Seguimiento Cliente Prospecto</FormLabel>
            </div>
            <Dialog className="dialog-seguimiento-cliente-prospecto" onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal}>
              <DialogTitle id="customized-dialog-title">Agregar seguimiento a Cliente Prospecto</DialogTitle>
              <IconButton className="close-modal" aria-label="close" onClick={handleCloseModal} ><CloseIcon /></IconButton>
              <DialogContent dividers>
                <div className="form-gst">
                  <FormControl className="select-control" error={errorSeguimiento.idTipoSeguimiento !== ''}>
                    <InputLabel id="distribuidora-label">Tipo de Seguimiento</InputLabel>
                    <Select labelId="lblIdTipoSeguimiento" id="idTipoSeguimiento" name="idTipoSeguimiento" value={seguimiento?.idTipoSeguimiento.toString() } label="Tipo de Seguimiento" onChange={handleSelectChangeSeguimiento}>
                      <MenuItem value={0}>Tipo de Seguimiento</MenuItem>
                      {tiposSeguimiento?.map((tipoSeg: any) => (
                        <MenuItem key={"item-" + tipoSeg?.id} value={tipoSeg?.id}>{tipoSeg?.nombre}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errorSeguimiento.idTipoSeguimiento}</FormHelperText>
                  </FormControl>
                  <TextField id="comentario" className="medium-textarea" onChange={handleInputTextChangeSeguimiento} multiline rows={2}
                    value={getSafeValue(seguimiento?.comentario)} label="Comentarios" color="secondary" error={errorSeguimiento.comentario !== ''}  helperText={errorSeguimiento.comentario} />
                  <SwitchLabelGTS label="¿Atendio?" id="atendio" checked={atendio} onChange={handleCheckAtendio} labelplacement="start" />
                </div>
              </DialogContent>
              <DialogActions><ButtonGST autoFocus onClick={guardarSeguimiento}>Guardar Seguimiento</ButtonGST></DialogActions>
            </Dialog>
            <TableContainer>
              <Table className="table-gts">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>#</StyledTableCell>
                    <StyledTableCell>Realizado por</StyledTableCell>
                    <StyledTableCell >¿Atendió?</StyledTableCell>
                    <StyledTableCell className="movile-hidden">Tipo Seguimiento</StyledTableCell>
                    <StyledTableCell className="movile-hidden">Comentario</StyledTableCell>
                    <StyledTableCell className="movile-hidden">Fecha</StyledTableCell>
                    <StyledTableCell align="right">Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {seguimientos?.map((row:any, idx: number) => {
                    return <StyledTableRow key={Math.random()}>
                      <StyledTableCell component="th" scope="row">{idx + 1}</StyledTableCell>
                      <StyledTableCell>{row?.nombre + ' ' + row?.apellido}</StyledTableCell>
                      <StyledTableCell><SwitchContainerGTS checked={row?.atendio === 1}  /></StyledTableCell>
                      <StyledTableCell className="movile-hidden">{row?.tipo_seguimiento}</StyledTableCell>
                      <StyledTableCell className="movile-hidden">{row?.comentario}</StyledTableCell>
                      <StyledTableCell className="movile-hidden">{row?.fecha_registro}</StyledTableCell>
                      <StyledTableCell align="right">
                        <ButtonGST onClick={() => handleClickDeleteSeguimiento(row?.id)}><DeleteIcon/></ButtonGST>
                      </StyledTableCell>
                    </StyledTableRow>
                  })}
                </TableBody>
              </Table>
          </TableContainer>
          </>
         : <></>}
        <div className={"panel-inputs estatus " + (idCliente ? 'active' : 'inactive')}>
          <span className="label">Distribuidora Alta: {cliente?.distribuidora !== null ? cliente?.distribuidora : 'Sin distribuidora'}</span>
          <span className="label">{", Fecha de alta: " + cliente?.fecha_alta}</span>
        </div>
        <div className="panel-actions">
          {tieneSeguimiento ? <ButtonGST onClick={agregarSeguimiento}>Agregar Seguimiento</ButtonGST> : <></>}
          <ButtonGST onClick={() => navigate(UrlBase + 'Clientes-Prospecto')}>Cancelar</ButtonGST>
          <ButtonGST onClick={guardarCliente}>Guardar</ButtonGST>
        </div>
      </Box>
    </>
  )
}

export default AgregarClienteProspecto;