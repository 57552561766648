import { IconButton, Pagination, Stack, TextField, InputAdornment } from "@mui/material";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import ListProductGTS from "../../molecules/ListProductGTS";
import React, { useEffect, useState } from "react";
import { getServiceData } from "../../store/global-services";
import { useNavigate } from "react-router-dom";
import { UrlSiteBase } from '../../Global';
import { IGlobalPoprop, IServiceData } from "../../interfaces/global-interface";
let { UrlBase } = UrlSiteBase();

const ListadoProductos = (globalProp?: IGlobalPoprop) => {
  const [productos, setProductos] = useState<any>();
  const [textSearch, setProductosBusqueda] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0); 
  const navigate = useNavigate();
  const pageSize = 10;

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setProductosBusqueda(value);
  }
  const getProductos = (page: number) => {
    let dataService: IServiceData  = { nombre: 'productos' };
    let paramService = {};
    if (page > 1) 
      paramService = { ...paramService, 'page': page };
    if (textSearch.trim().length > 0)
      paramService = { ...paramService, 'search': textSearch.trim() };
    dataService = { ...dataService, 'parametros': paramService };
    getServiceData(dataService).then(data => {
      if (data.success) {
        setProductos(data.productos);
        setTotalPages(data.pagination.last_page);
      }
    });
  }
  useEffect(() => {
    getProductos(page)
  }, [page]);
  const handleClick = (idUsuario: number) => {
    navigate(idUsuario > 0 ? `${UrlBase}Producto/Editar/${idUsuario}` : `${UrlBase}Producto/Agregar`);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') { 
      getProductosSearch(); 
      event.preventDefault(); 
    }
  };
  const getProductosSearch = () => {
    getProductos(1);
  };
  return (
    <>
      <div className="panel-search">
        <TextField id="search" onChange={handleSearchTextChange} value={textSearch} label="Buscar" color="secondary"
          InputProps={{ endAdornment: 
            <InputAdornment position="end">
              <IconButton onClick={getProductosSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment> }} onKeyDown={handleKeyDown}/>
      </div>
      <div className="panel-actions">
        <ButtonGST onClick={() => handleClick(0)}>Agregar <AddIcon /></ButtonGST>
      </div>
      <ListProductGTS handleClick={handleClick} productos={productos} />
      { totalPages > 1 ?
        <Stack spacing={2}>
          <Pagination count={totalPages} page={page} onChange={handlePaginationChange} />
        </Stack> 
        : <></>
      }
    </>
  )
}

export default ListadoProductos;