import { Box, FormControl, FormHelperText, InputLabel, InputAdornment, IconButton, TextField, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IUsuario, UsuarioDefault, IUsuarioError, UsuarioError } from "../../interfaces/usuario-interface";
import { getServiceData, saveServiceData } from "../../store/global-services";
import ButtonGST from "../../atoms/Controls/ButtonGTS";
import { SwitchLabelGTS } from "../../atoms/Controls/SwitchGTS";
import { UrlSiteBase, isEmpty } from '../../Global';
import { IGlobalPoprop } from "../../interfaces/global-interface";

let { UrlBase } = UrlSiteBase();

const AgregarUsuario = (globalProp?: IGlobalPoprop) => {
  const { idUsuario } = useParams();
  const [usuario, setUsuario] = useState<IUsuario>(UsuarioDefault);
  const [error, setError] = useState<IUsuarioError>(UsuarioError);
  const [errorConfPass, setConfPass] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(idUsuario ? (usuario?.estatus === 1 ? true : false) : true);
  const [perfiles, setPerfil] = useState<any>();
  const [distribuidoras, setDistribuidoras] = useState<any>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassConf = () => setShowPassConf((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownPassConf = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }
  const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setUsuario(usuario => ({ ...usuario, [id]: value }));
  }
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setUsuario(usuario => ({...usuario, [name]: value }));
  }
  const handleCheckbox = () => {
    setIsActive(!isActive);
    setUsuario(usuario => ({ ...usuario, ['estatus']: !isActive ? 1 : 0 }));
  }
  

  const guardarUsuario = () => {
    if (usuario.password_confirm !== usuario.password) {
      setConfPass('Las contraseñas no coinciden');
      return;
    }
    saveServiceData( {nombre: 'usuario', id: idUsuario, datos: usuario}).then( data => {
      if (data.success) {
        navigate(UrlBase + 'Usuario');
        if (globalProp && globalProp.snackHandleOpen)
          globalProp.snackHandleOpen({open: true, message: data.message});
      }
      else {
        setError(UsuarioError);
        for (const [key, value] of Object.entries(data.message)) {
          setError(error => ({...error, [key]: value}));
        }
      }
    });
  };
  useEffect(() => {
    getServiceData({ nombre: 'perfiles', parametros: {estatus: 1}}).then(data => {
      if (data.success && data.perfiles)
        setPerfil([...data.perfiles]);
    });
    getServiceData({ nombre: 'distribuidoras', parametros: {estatus: 1}}).then(data => {
      if (data.success && data.distribuidoras)
        setDistribuidoras([...data.distribuidoras]);
    });
  }, []);

  useEffect(() => {
    if (perfiles && distribuidoras && idUsuario)
      getServiceData({id: idUsuario, nombre : 'usuario' }).then(data => {
        if (data.success && data.usuario){
          setUsuario(usuario => ({ ...usuario, ...data.usuario }));
          setIsActive(data.usuario.estatus === 1 ? true : false);
        }
      });
  }, [perfiles, distribuidoras, idUsuario]);
  return (
    <>
      <Box className="form-gst" component="form" noValidate autoComplete="off">
        <div className="panel-inputs">
          <TextField id="id" value={usuario.id} label="Id" aria-readonly={true} disabled={true} color="secondary" />
          <TextField id="usuario" onChange={handleInputTextChange} value={usuario.usuario} label="* Usuario" aria-readonly={true} color="secondary" error={error.usuario !== ''} helperText={error.usuario} />
          <TextField id="password" onChange={handleInputTextChange} type={showPassword ? "text" : "password"} value={usuario.password} label="* Contraseña" color="secondary"
            error={error.password !== ''}  helperText={error.password}
            InputProps={{ endAdornment: 
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end" onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment> }} />
          <TextField id="password_confirm" onChange={handleInputTextChange} type={showPassConf ? "text" : "password"} value={usuario.password_confirm} label="Confirma contraseña" 
            color="secondary" error={errorConfPass !== ''} helperText={errorConfPass}  
            InputProps={{ endAdornment: 
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassConf} edge="end" onMouseDown={handleMouseDownPassConf}>
                  {showPassConf ? <VisibilityOff /> : <Visibility />}
                </IconButton> 
              </InputAdornment>}}  />
          <TextField id="nombre" onChange={handleInputTextChange} value={usuario.nombre} label="* Nombre(s)" color="secondary" error={error.nombre !== ''}  helperText={error.nombre} />
          <TextField id="apellido" onChange={handleInputTextChange} value={usuario.apellido} label="Apellido(s)" color="secondary" error={error.apellido !== ''}  helperText={error.apellido} />
          <TextField id="email" onChange={handleInputTextChange} value={usuario.email} label="* Correo Electrónico" error={error.email !== ''}  helperText={error.email} InputLabelProps={{ shrink: isEmpty(usuario.email) }} />
          <FormControl className="select-control" error={error.idPerfil !== ''}>
            <InputLabel id="perfil-label">Perfil</InputLabel>
            <Select labelId="Perfil" id="perfil" name="idPerfil" value={usuario.idPerfil} label="Perfil" onChange={handleSelectChange}>
              {perfiles?.map((perfil: any) => (
                <MenuItem key={"item-" + perfil?.id} value={perfil?.id}>{perfil?.nombre}</MenuItem>
              ))};
            </Select>
            <FormHelperText>{error.idPerfil}</FormHelperText>
          </FormControl>
          <FormControl className="select-control" error={error.idDistribuidora !== ''}>
            <InputLabel id="distribuidora-label">Distribuidora</InputLabel>
            <Select labelId="Distribuidora" id="distribuidora" name="idDistribuidora" value={usuario?.idDistribuidora} label="Distribuidora" onChange={handleSelectChange}>
              <MenuItem value={0}>Sin distribuidora</MenuItem>
              {distribuidoras?.map((distribuidora: any) => (
                <MenuItem key={"item-" + distribuidora?.id} value={distribuidora?.id}>{distribuidora?.nombre}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{error.idDistribuidora}</FormHelperText>
          </FormControl>
          <SwitchLabelGTS label="Activo" id="estatus" checked={isActive} onChange={handleCheckbox} />
        </div>  
        <div className="panel-actions">
          <ButtonGST onClick={() => navigate(UrlBase + 'Usuario')}>Cancelar</ButtonGST>
          <ButtonGST onClick={guardarUsuario}>Guardar</ButtonGST>
        </div>
      </Box>
    </>
  )
}

export default AgregarUsuario;