import React, { EventHandler, MouseEventHandler } from 'react';
import { NavDropdown, NavDropdownProps } from 'react-bootstrap';
import NavLink, { NavLinkProps } from 'react-bootstrap/NavLink';
import { LinkProps } from 'react-router-dom';

interface NavLinkGTSProps extends NavLinkProps {
    to: string | React.Component
}
interface NavDropdownGTSProps extends NavDropdownProps {
    keyValue: string
}
interface NavDropdownItemGTSProps {
    key: string,
    to: string,
    children: JSX.Element,
    as: React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>,
    id?: string,
    className?: string,
    onClick? : MouseEventHandler,
    onSelect?: EventHandler<any>,
    eventKey? : string,
    selected? : any
}
const NavGTS = { 
    DropdownItem : (navProps : NavDropdownItemGTSProps) => {
        return (
            <>
            <NavDropdown.Item id={navProps.id} as={navProps.as} to={navProps.to} aria-selected={navProps.selected}
                className= { 'font-color-principal ' + (navProps.className ? navProps.className : '')} 
                onClick={navProps.onClick} eventKey={ navProps.eventKey } onSelect={navProps.onSelect} >
                {navProps.children}
            </NavDropdown.Item>
            </>
        );
    },
    Dropdown : (navProps : NavDropdownGTSProps) => {
        return (
            <>
            <NavDropdown id={navProps.id} key={navProps.keyValue} title={navProps.title} className={navProps.className}>
                {navProps.children}
            </NavDropdown>
            </>
        );
    },
    Link : (navProps: NavLinkGTSProps) : JSX.Element => {
        return (
            <>
            <NavLink {...navProps}>
                {navProps.children}
            </NavLink>
            </>
        );
    }
}

export default NavGTS;